<ng-container
  *ngIf="{
    mfaOptions: authenticationState.signInMfaOptions$ | async,
    newPasswordOptions: authenticationState.completePasswordOptions$ | async
  } as signInOptions"
>
  <mat-card *ngIf="message && message.title" class="mb-5" id="message-container" tid="message-container">
    <app-content style="margin: 1.2rem">
      <app-body-md ink="ink-white" bold>{{ message.title }}</app-body-md>
    </app-content>
    <p *ngIf="message.body">{{ message.body }}</p>
  </mat-card>

  <ng-container *ngIf="!(signInOptions.mfaOptions || signInOptions.newPasswordOptions)" [formGroup]="signInFormGroup">
    <h2 style="font-size: 3rem; font-weight: 700; line-height: normal; margin-bottom: 2.4rem">Login</h2>
    <app-button
      icon="contact_support"
      (click)="goToHelp()"
      [iconLeft]="true"
      iconColor="primary-action"
      iconHoverColor="primary-action"
      buttonText="Help"
      class="help-button"
      theme="ink-02"
      [uppercaseText]="false"
    ></app-button>

    <form (ngSubmit)="signIn()">
      <app-input
        tid="username"
        type="email"
        [emailField]="true"
        formPropertyName="username"
        label="Email"
        placeholder="Please enter your login email"
        appAutoFocus
      ></app-input>

      <app-password-input
        label="Password"
        tId="password"
        formPropertyName="password"
        placeholder="Enter your password"
        style="margin-top: 2.4rem; display: block"
      ></app-password-input>

      <app-button class="forgot-password-button" (click)="requestResetPassword()" basicButton="true" tid="forgot-password-button"
        >Forgot your password</app-button
      >

      <div style="text-align: right; padding-top: 4rem">
        <app-button tabindex="0" theme="primary-action" type="submit" [loading]="fetching$ | async" tid="submit-button">Login</app-button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="signInOptions.mfaOptions" [formGroup]="mfaFormGroup">
    <form (ngSubmit)="signInMfa()">
      <app-h4 noMargin left>Secure Code</app-h4>
      <p>We sent a security code to {{ signInOptions.mfaOptions.challengeMobileNumber }}.</p>

      <app-input
        tid="sms"
        type="text"
        formPropertyName="mfaCode"
        label="Security code"
        placeholder="Security code"
        appAutoFocus
        required
        autocomplete="off"
        title="Security code"
        maxlength="6"
      ></app-input>

      <div class="footer-actions">
        <div id="cancel-container">
          <app-button theme="primary-action" (click)="cancel()" basicButton="true">Back</app-button>
        </div>
        <div class="action-container">
          <app-button theme="primary-action" type="submit" [loading]="fetching$ | async" tid="submit-button">Login</app-button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="signInOptions.newPasswordOptions" [formGroup]="confirmPasswordFormGroup">
    <form (ngSubmit)="completeNewPassword()">
      <app-password-reset-controls #passwordResetControls></app-password-reset-controls>
      <div class="footer-actions">
        <div id="cancel-container">
          <app-button theme="primary-action" (click)="cancel()" basicButton="true">Back</app-button>
        </div>
        <div class="action-container">
          <app-button theme="primary-action" type="submit" [loading]="fetching$ | async" tid="submit-button">Create Password</app-button>
        </div>
      </div>
    </form>
  </ng-container>
</ng-container>
