import { ChangeDetectionStrategy, Component, Injectable, OnInit } from '@angular/core';
import { AuthenticationService } from '@auth-n/authentication.service';
import { environment } from '@env/environment';
import { Amplify } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-sso-landing',
  templateUrl: './sso-landing.component.html',
  styleUrl: './sso-landing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoLandingComponent {
  constructor(private authenticationService: AuthenticationService) {
    Amplify.configure(environment.cognitoSSOConfig);
    this.authenticationService.onAuthenticated().then(() => console.log(`Authenticated`));
  }
}
